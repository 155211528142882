import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { BottomButtonsWrapper, Container, Content, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Contract } from '../../../interfaces';
import { randomId } from '../../../utils';
import Details from '../Components/Details';
import { useNavigate } from 'react-router-dom';

const AddNewContract: React.FC = () => {    
    const emptyContract = {
        id: 0,
        title: '',
        type: '',
        supplierId: 0,
        country: '',
        department: '',
        startDate: '',
        endDate: '',
        noticePeriodDate: '',
        currency: '',
        estimatedValue: '',
        description: '',
        items: [],
        orders: [],
        deliveries: [],
        status: 'open'
        
    }

    const [newContract, setNewContract] = useState<Contract>(emptyContract);

    const { contracts, setContracts } = useSupplier();

    const submitContract = () => {
        const newId = randomId()
        setContracts([...contracts, { ...newContract, id: newId }]);
        navigate(URLS.contracts)
    };

    const navigate = useNavigate()

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Add New Contract</Title>
                    
                    <Details
                        contract={newContract}
                        setContract={setNewContract}
                        isEditModeOn
                    />
                    
                    <BottomButtonsWrapper>
                        <Button onClick={submitContract}>Submit Contract</Button>
                        <Button onClick={() => navigate(URLS.contracts)}>Cancel</Button>
                    </BottomButtonsWrapper>
                </Content>
            </Container>
        </>
    );
};

export default AddNewContract;
