import React from 'react';
import { useLocation } from 'react-router-dom';
import AddNewContract from './new';
import CreateFromOffer from './fromOffer';

const AddContract: React.FC = () => {    
    const { state } = useLocation();
    const { contract, requestId } = state || {};

    return (
        <>
            { contract
                ? <CreateFromOffer contract={contract} requestId={requestId} />
                : <AddNewContract />
            }
        </>
    );
};

export default AddContract;
