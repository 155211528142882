import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import { useSupplier } from '../../../context/SupplierContext';
import { Delivery } from '../../../interfaces';
import { Flex, InputWrapper, Label, Name, TableBox } from '../styles';
import Input from '../../../components/Input';
import Table from '../../../components/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import { orderDeliveriesColumns } from '../config';
import { suppliers } from '../../../mocks';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import { randomId } from '../../../utils';

const AddDelivery: React.FC = () => {

    const { contracts, setContracts } = useSupplier();

    const location = useLocation();
    const state = location.state;

    const { orderId, contractId } = state;
    const contract = contracts.find((con) => con.id === contractId)
    const contractIndex = contracts.findIndex((con) => con.id === contractId)
    const order = contract?.orders.find(({ poNumber }) => poNumber === orderId)

    const [newDelivery, setNewDelivery] = useState<Delivery>({
        id: randomId(),
        deliveryDate: '',
        method: '',
        items: order?.items || [],
        status: 'issued',
        deliveriedVia: '',
        invoiceNumber: '',
        address: '',
        expectedOn: ''
    });

    const navigate = useNavigate()

    const addDelivery = () => {

        const contractIndex = contracts.findIndex(({ id }) => id === contractId)
        const currentDeliveries = contracts[contractIndex].deliveries || []

        contracts[contractIndex].deliveries = [...currentDeliveries, newDelivery]
        setContracts(contracts);

        navigate(URLS.contractDetails, { state: { orderId, contractId: contractId, defaultTab: 'deliveries' }})
    }

    // const submitOrder = () => {
    //     if (contract) {
    //         const contractIndex = contracts.findIndex(({ id }) => id === contractId)
    //         const currentOrders = contracts[contractIndex].orders || []

    //         contracts[contractIndex].orders = [...currentOrders, newOrder]
    //         setContracts(contracts);
    //         navigate(URLS.contractDetails, { state: { contractId, defaultTab: 'purchaseOrders' }} )
    //     }
    // };


    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                

                <Content>
                    <Title>Add New delivery</Title>

                    <Flex>
                        <TableBox>
                            Order { orderId }
                            <p><Name>PO Name:</Name> {order?.poName}</p>
                            <p><Name>PO status:</Name> {order?.status}</p>
                            <p><Name>Supplier name:</Name> {suppliers.find(sup => sup.id === contract?.supplierId)?.company}</p>
                            <p><Name>Buyer name:</Name> </p>
                            <p><Name>Buyer address:</Name> </p>
                        </TableBox>
                        <TableBox>
                            <InputWrapper>
                                <label htmlFor='deliveryDate'>Deliveried on</label>
                                <Input
                                    value={newDelivery?.deliveryDate || ''}
                                    type='date'
                                    name='deliveryName'
                                    onChange={(value) => setNewDelivery({...newDelivery, deliveryDate: value})}   
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <label htmlFor='deliveriedVia'>Deliveried via</label>
                                <Input
                                    value={newDelivery?.deliveriedVia || ''}
                                    type='text'
                                    name='deliveriedVia'
                                    onChange={(value) => setNewDelivery({...newDelivery, deliveriedVia: value})}   
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <label htmlFor='invoiceNumber'>Invoice number</label>
                                <Input
                                    value={newDelivery?.invoiceNumber || ''}
                                    type='text'
                                    name='invoiceNumber'
                                    onChange={(value) => setNewDelivery({...newDelivery, invoiceNumber: value})}   
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <label htmlFor='address'>Address</label>
                                <Input
                                    value={newDelivery?.address || ''}
                                    type='text'
                                    name='address'
                                    onChange={(value) => setNewDelivery({...newDelivery, address: value})}   
                                />
                            </InputWrapper>
                            <InputWrapper>
                                <label htmlFor='expectedOn'>Expected on</label>
                                <Input
                                    value={newDelivery?.expectedOn || ''}
                                    type='date'
                                    name='expectedOn'
                                    onChange={(value) => setNewDelivery({...newDelivery, expectedOn: value})}   
                                />
                            </InputWrapper>
                        </TableBox>

                        
                    </Flex>

                    <Table
                        rowData={newDelivery?.items.map(item => { return {
                            ...item,
                            quantityOrdered: item.quantity,
                        }}) || []}
                        columns={orderDeliveriesColumns}
                        height={300}
                        // onCellChanged={onCellChanged}
                    /> 
                    <div style={{ paddingTop: '20px'}}>
                        <Checkbox
                           labelStyle={{ color: 'black', fontWeight: 400 }}
                           label='Auto fill with remaining quantity'
                           checked={false}
                           name='autofill'
                       />
                        <p>General Notes</p>
                        <Input
                            textarea
                            name='generalNote'
                        />
                    </div>

                    <div style={{ paddingTop: '30px', width: '400px' }}>
                        <Label>
                            <label htmlFor='attachment'>Attach supporting document</label>
                        </Label>
                        <Input name='attachment' type="file" />
                    </div>

                 <Button onClick={addDelivery}>Add delivery</Button>

                </Content>

            </Container>
            
        </>
    );
};

export default AddDelivery;
