import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title, Tabs, Tab, BottomButtonsWrapper } from '../../../App';
import { TABS, URLS } from '../../../constants';
import { useSupplier } from '../../../context/SupplierContext';
import Details from '../Components/Details';
import Button from '../../../components/Button';
import { Contract } from '../../../interfaces';
import Modal from '../../../components/Modal';
import SendToSupplier from '../Components/SendToSupplierModal';
import PO from '../Components/PurchaseOrders';
import Table from '../../../components/Table';
import { deliveriesColumns } from '../config';

const ContractDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const { contractId, defaultTab } = state;
    const { contracts, setContracts } = useSupplier();

    const contractIndex = contracts.findIndex(({ id }) => id === contractId) || 0
    const contract = contracts[contractIndex]

    const [newContract, setNewContract] = useState(contract);

    const updateContract = (newContract: Contract) => {
        setNewContract(newContract)
    }

    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [activeTab, setActiveTab] = useState(defaultTab || 'details');
    const [isEdit, setIsEdit] = useState(false);

    const save = () => {
        setIsEdit(false)
        contracts[contractIndex] = newContract
        setContracts([...contracts])
    }

    const send = ({ to, from, subject, content }: { to: string, from: string, subject: string, content: string }) => {
        setModalIsOpen(false)
        setIsEdit(false);

        console.log({ to, from, subject, content})
    }

    const navigate = useNavigate()

    const tabConfig = [
        {
          label: "Contract details",
          key: "details",
          component:
            <>
                <Details
                    contract={newContract}
                    setContract={updateContract}
                    isEditModeOn={isEdit}
                    showProgress
                />
          
                <BottomButtonsWrapper>
                { isEdit
                    ? <Button onClick={save}>Save</Button>
                    : <>
                        <Button style={{ width: 190 }} onClick={() => navigate(URLS.contracts)}>Back to contract list</Button>
                        <Button onClick={() => setIsEdit(true)}>Edit contract</Button>
                        <Button onClick={() => setModalIsOpen(true)}>Send to supplier</Button>
                    </> 
                }
                 </BottomButtonsWrapper>
            </>
        },
        {
            label: "Purchase Orders",
            key: "purchaseOrders",
            component:
              <PO contract={contract} />
          },
          {
            label: "Deliveries",
            key: "deliveries",
            component:
                <Table rowData={contract.deliveries || []} columns={deliveriesColumns} height={300} />
          },

      ];

      const handleTerminateContract = () => {
        const newContract = { ...contract, status: 'close'}
        contracts[contractIndex] = newContract
        setContracts([...contracts])
    };

    const handleOpenContract = () => {
        const newContract = { ...contract, status: 'open'}
        contracts[contractIndex] = newContract
        setContracts([...contracts])
    };

    const handleExtendDueDate = () => {
        const today = new Date();
        const futureDate = new Date(contract.endDate || today); // Clone the date
        futureDate.setDate(futureDate.getDate() + 30); // Add 30 days
        
        // Format the date as yyyy-mm-dd
        const formattedDate = `${futureDate.getFullYear()}-${
          (futureDate.getMonth() + 1).toString().padStart(2, '0')}-${
          futureDate.getDate().toString().padStart(2, '0')}`;
          
        const newContract = { ...contract, endDate: formattedDate }

        contracts[contractIndex] = newContract
        setContracts([...contracts])

        updateContract(newContract)
    };

    const handleExportPDF = () => {
        console.log("Export PDF clicked");
    };

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Contract { contract?.id }</Title>

                    <div style={{ display: 'flex', gap: '15px', marginBottom: '20px', maxWidth: 550, marginLeft: 'auto' }}>
                        { contract.status === 'open'
                            ? <Button onClick={handleTerminateContract}>Terminate Contract</Button>
                            : <Button onClick={handleOpenContract}>Open Contract</Button> }
                        <Button onClick={handleExtendDueDate}>Extend Due Date</Button>
                        <Button onClick={handleExportPDF}>Export in PDF</Button>
                    </div>

                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setActiveTab(tabItem.key)}
                                active={activeTab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>
                    
                    { tabConfig.find(({ key }) => key === activeTab)?.component }

                </Content>

                <Modal
                    isOpen={modalIsOpen}
                    onClose={() => setModalIsOpen(false)}
                >
                    <SendToSupplier send={send} cancel={() => setModalIsOpen(false)} />
                </Modal>
            </Container>
        </>
    );
};

export default ContractDetails;