import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { BottomButtonsWrapper, Container, Content, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Request } from '../../../interfaces';
import { randomId } from '../../../utils';
import RequestForm from './RequestForm';
import { Label, TableBox } from '../styles';
import RadioInput from '../../../components/RadioInput';
import MultiSelect from '../../../components/Multiselect';
import { suppliers } from '../../../mocks';
import { useNavigate } from 'react-router-dom';

const AddRequest: React.FC = () => {
    const [newRequest, setNewRequest] = useState<Request>({
      id: 0,
      name: "",
      productCategory: "",
      certificationsRequired: "",
      status: "draft",
      dueDate: "",
      details: '',
      industry: '',
      department: '',
      startDate: '',
      currency: '',
      budget: '',
      location: '',
      items: [],
      onlySendToSuppliers: [],
      applications: []
    });

    const { requests, setRequests } = useSupplier();

    const [requestType, setRequestType] = useState('all')
    const navigate = useNavigate();

    const submitRequest = (isDraft?: boolean) => {
        const newId = randomId()
        setRequests([...requests, { ...newRequest, status: isDraft ? 'draft' : 'active', id: newId, onlySendToSuppliers: sendToSuppliers }]);
        navigate(URLS.requests)
    };

    const [sendToSuppliers, setSendToSuppliers] = useState<number[]>([])
    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.requests} />
                
                <Content>
                    <Title>Add New Opportunity</Title>
                    
                    <RequestForm
                        setNewRequest={setNewRequest}
                        newRequest={newRequest}
                        isEditModeOn={true}
                    />

                    <TableBox style={{ marginTop: '20px'}}>
                        <Label>Suppliers</Label>
                        <RadioInput
                            name='requestType'
                            value={requestType}
                            options={[{ label: 'Post as public sourcing opportunity', value: 'all'}, { label: 'Send to specific suppliers', value: 'toSuppliers'}]}
                            onChange={(value) => setRequestType(value as string)}
                        />

                        { requestType === 'toSuppliers' && 
                            <MultiSelect
                                options={suppliers.map(({ company, id }) => {
                                    return { label: company, value: id.toString() }
                                })}
                                name='requestType'
                                placeholder='Select suppliers'
                                onChange={(values) => setSendToSuppliers(values.map(v => parseInt(v)))}
                            />
                        }
                    </TableBox>

                    <BottomButtonsWrapper>
                        <Button onClick={() => submitRequest(false)}>Publish</Button>
                        <Button onClick={() => submitRequest(true)}>Save as draft</Button>
                    </BottomButtonsWrapper>
                </Content>
            </Container>
        </>
    );
};

export default AddRequest;
