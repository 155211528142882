import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { BottomButtonsWrapper, Container, Content, Tab, Tabs, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Application, Request } from '../../../interfaces';
import RequestForm from '../AddRequest/RequestForm';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../../../components/Modal';
import SendToSupplier from '../SendToSupplierModal';
import { applicationsColumns } from '../config';
import Table from '../../../components/Table';
import MultiSelect from '../../../components/Multiselect';
import { randomId } from '../../../utils';

const RequestDetailsView: React.FC = () => {
    
    const location = useLocation();
    const state = location.state;

    const { requestId } = state;
    const { requests, setRequests, suppliers } = useSupplier();

    const reqIndex = requests.findIndex((req) => req.id === requestId) || 0
    const request = requests[reqIndex]

    const [newRequest, setNewRequest] = useState(request);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalInviteIsOpen, setModalInviteIsOpen] = useState(false);

    const [suppliersToInvite, setSuppliersToInvite] = useState<number[]>([]);

    const updateRequest = (newReq: Request) => {
        setNewRequest(newReq)
    }

    const navigate = useNavigate();

    const [isEdit, setIsEdit] = useState(false);

    const save = () => {
        setIsEdit(false)
        requests[reqIndex] = newRequest
        setRequests([...requests])
        setModalIsOpen(true)
    }

    const sendInvite = () => {
        const newApplications = suppliersToInvite
            .map(supId => { return { id: randomId(), supplierId: supId, status: 'waiting_for_offer' }}) as Application[]

        requests[reqIndex].applications = [...requests[reqIndex].applications, ...newApplications] 
        setRequests([...requests])

        setModalInviteIsOpen(false)
    }

    const handleClickOnCell = (cell: any) => {
        if (cell.colDef.field !== 'reminder')
        navigate(URLS.applicationDetails, { state: { appId: cell.data.id, requestId } });
      };

    const [activeTab, setActiveTab] = useState('details');

    const tabConfig = [
        {
          label: "Contract details",
          key: "details",
          component:
            <>
                <RequestForm
                        setNewRequest={updateRequest}
                        newRequest={newRequest}
                        isEditModeOn={isEdit}
                    />
                    <BottomButtonsWrapper>
                { isEdit
                    ? <Button onClick={save}>Save</Button>
                    : <>
                        <Button onClick={() => setIsEdit(true)}>Edit opportunity</Button>
                        <Button onClick={() => navigate(URLS.requests)}>Close</Button>
                    </>   
                }
                 </BottomButtonsWrapper>
            </>
        },
        {
            label: 'Applications',
            key: 'applications',
            component:
                <>
                    <Table
                        columns={applicationsColumns}
                        rowData={request.applications.map(a => { return { ...a, supplierName: suppliers.find(sup => sup.id === a.supplierId)?.company}})}
                        height={300}
                        onCellClicked={(cell) => handleClickOnCell(cell)}
                    />
                    <BottomButtonsWrapper>
                        <Button style={{ width: 200 }} onClick={() => setModalInviteIsOpen(true)}>Invite another supplier</Button>
                        <Button>Compare offers</Button>
                    </BottomButtonsWrapper>

                    <Modal isOpen={modalInviteIsOpen} onClose={() => setModalInviteIsOpen(false)}>
                        <h2>Invite another supplier</h2>
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>Choose supplier(s):</div>
                        <MultiSelect
                            options={suppliers.map(({ id, company }) => { return { value: id.toString(), label: company }})}
                            name='suppliers'
                            onChange={(values) => setSuppliersToInvite(values.map(v => parseInt(v)))}
                        />
                        <div style={{ display: 'flex', padding: 15, gap: 10 }}>
                            <Button onClick={sendInvite}>Send</Button>
                            <Button onClick={() => setModalInviteIsOpen(false)}>Cancel</Button>
                        </div>
                    </Modal>
                </>
        },
        {
            label: 'Documents',
            key: 'documents',
            component:
                <>
                   
                </>
        },
        {
            label: 'Activity log',
            key: 'activityLog',
            component:
                <>
                   
                </>
        }
    ]

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.requests} />
                
                <Content>
                    <Title>Opportunity {requestId}</Title>
                    
                    
                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setActiveTab(tabItem.key)}
                                active={activeTab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>
                    
                    { tabConfig.find(({ key }) => key === activeTab)?.component }

          
                </Content>
            </Container>

            <Modal
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
            >
                    <SendToSupplier cancel={() => setModalIsOpen(false)} />
                        
            </Modal>
            
        </>
    );
};

export default RequestDetailsView;
