export const colors = {
    darkGreen: "#49938a",
    mediumGreen: "#5dc3ba",
    lightGreen: "#6ce2d6",
    darkGreenHover: "#2b5752",
    lightGrey: "#f7f7f7",
    mediumGrey: "#f3f3f3",
    grey: "#e6e6e6",
    orange: "#d6b57d",
    darkOrange: "#bb6125",
    red: "#ff0000",
    green: "green",
    tableBorder: "#e9e9e9"
  };

  export const URLS = {
    home: '/',
    terms: '/terms-and-conditions',
    suppliers: '/suppliers',
    supplierDetails: '/suppliers/details',
    contracts: '/contracts',
    addContract: '/add-contract',
    contractDetails: '/contracts/details',
    dashboard: '/dashboard',
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot-password',
    signupCompany: '/signup/company',
    addSupplier: '/suppliers/add-supplier',
    firstLogin: '/welcome',
    profile: '/profile', 
    settings: '/settings',
    orderDetails: '/orders/details',
    deliveryDetails: '/orders/delivery/details',
    addOrder: '/orders/add-order',
    addDelivery: '/orders/add-delivery',
    requests: '/opportunities',
    addRequests: '/opportunities/add-opportunity',
    requestDetails: '/opportunities/details',
    applicationDetails: '/opportunities/details/application'
  }

  export const TABS = {
    suppliers: 'suppliers',
    contracts: 'contracts',
    dashboard: 'dashboard',
    profile: 'profile',
    settings: 'settings',
    requests: 'opportunities'
  }

  export const inputBorderRadius = '15px';
  export const borderRadius = {
    input: '15px',
    button: '15px'
  }