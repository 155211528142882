import ReminderButton from "./ReminderButton"

export const requestItemsColumns = [
    {
        field: 'id',
        filter: true,
        maxWidth: 80
    },
    {
        field: 'productCategory',
        filter: true,
    },
    {
        field: 'material',
        filter: true,
    },
    {
        field: 'capacityRange',
        filter: true,
    },
    {
        field: 'quantity',
        filter: true,
    },
    {
        field: 'unitPrice',
        filter: true,
    }
]

export const applicationsColumns = [
    {
        field: 'id',
        filter: true,
        maxWidth: 80
    },
    {
        field: 'supplierName',
        filter: true,
    },
    {
        field: 'status',
        filter: true,
    },
    {
        field: 'reminder',
        filter: false,
        cellRenderer: ReminderButton,
    },
    
]
