import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { BottomButtonsWrapper, Container, Content, Title } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Item, Order } from '../../../interfaces';
import { ButtonWrapper, Flex, GeneralInfo, InputWrapper, TableBox } from '../styles';
import { randomId } from '../../../utils';
import Input from '../../../components/Input';
import Table from '../../../components/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import { contractItemsColumns } from '../config';
import Modal from '../../../components/Modal';
import RoundButton from '../../../components/RoundButton';

const AddOrder: React.FC = () => {

    const [newOrder, setNewOrder] = useState<Order>({
        poNumber: randomId(),
        poName: '',
        dateCreate: new Date().toLocaleDateString(),
        dateIssued: '',
        dueDate: '',
        deliveryDate: '',
        status: 'pending',
        total: 0,
        items: []
    });

    const { contracts, setContracts } = useSupplier();

    const location = useLocation();
    const state = location.state;

    const { contractId } = state;
    const contract = contracts.find((con) => con.id === contractId)

    const navigate = useNavigate()

    const submitOrder = () => {
        if (contract) {
            const contractIndex = contracts.findIndex(({ id }) => id === contractId)
            const currentOrders = contracts[contractIndex].orders || []

            contracts[contractIndex].orders = [...currentOrders, newOrder]
            setContracts(contracts);
            navigate(URLS.contractDetails, { state: { contractId, defaultTab: 'purchaseOrders' }} )
        }
    };

    const [showAddItemModal, setShowAddItemModal] = useState(false)

    const [selectedItemsIds, setSelectedItemsIds] = useState<number[]>([])

    const addItems = () => {
        if (contract) {
            const items: Item[] = []
            
            contract.items.forEach((item) => {
                if (selectedItemsIds.find(itemId => itemId === item.id)) {
                    items.push({ ...item})
                }
            });
            
            const total = items.reduce((sum, { subtotal }) => sum + subtotal,  0)

            setNewOrder({
                ...newOrder,
                items,
                total
            })
            setShowAddItemModal(false)
        }
    }



    const onCellChanged = ({ data: newRow }: { data: any}) => {
        console.log({ newRow })
        // const itemToUpdateIndex = newOrder.items.findIndex(item => item.id === newRow.id)
        // const itemToUpdate = newOrder.items[itemToUpdateIndex]
        // const newItem = {...itemToUpdate, quantity: newRow.quantity, subtotal: newRow.unitPrice * newRow.quantity  }
        // const total = newOrder.items.reduce((sum, { subtotal }) => sum + subtotal,  0)

        // setNewOrder({
        //     ...newOrder,
        //     items: [...newOrder.items],
        //     total
        // })
    }

    console.log({ contract })

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Add New Order</Title>

                    <Flex>
                        <GeneralInfo>
                            <TableBox>
                                <InputWrapper>
                                    <label htmlFor='name'>Order Name</label>
                                    <Input
                                        value={newOrder?.poName || ''}
                                        type='text'
                                        name='name'
                                        onChange={(value) => setNewOrder({...newOrder, poName: value})}   
                                    />
                                </InputWrapper>
                                <InputWrapper>
                                    <label htmlFor='dueDate'>Due date</label>
                                    <Input
                                        value={newOrder?.dueDate || ''}
                                        type='date'
                                        name='name'
                                        onChange={(value) => setNewOrder({...newOrder, dueDate: value})}   
                                    />
                                </InputWrapper>
                                <InputWrapper>
                                    <label htmlFor='deliveryDate'>Delivery date</label>
                                    <Input
                                        value={newOrder?.deliveryDate || ''}
                                        type='date'
                                        name='deliveryDate'
                                        onChange={(value) => setNewOrder({...newOrder, deliveryDate: value})}   
                                    />
                                </InputWrapper>
                            </TableBox>
                        </GeneralInfo>
                    </Flex>
                
                    <Table
                        rowData={newOrder?.items || []}
                        columns={contractItemsColumns(true)}
                        height={300}
                        onCellChanged={onCellChanged}
                    /> 
                    <div style={{ marginTop: 10 }}>
                        <RoundButton onClick={() => setShowAddItemModal(true)} text='Add items'/>
                    </div>

                    <BottomButtonsWrapper>
                        <Button onClick={submitOrder}>Submit order</Button>
                        <Button onClick={() => navigate(URLS.contracts)}>Cancel</Button>
                    </BottomButtonsWrapper>
                </Content>
            </Container>
            <Modal isOpen={showAddItemModal} width='90%'>
                <Table
                    rowData={contract?.items || []}
                    columns={contractItemsColumns(false)}
                    height={300}
                    checkboxes
                    onSelect={(rows) => setSelectedItemsIds(rows.map((row: { id: string; }) => row.id))}
                    selectedIds={selectedItemsIds.map(id => id.toString())}
                /> 

                <div style={{ display: 'flex', gap: 10, justifyContent: 'center', marginTop: 10 }}>
                    <Button onClick={addItems} width='100px'>Add items</Button>
                    <Button onClick={() => setShowAddItemModal(false)} width='100px'>Cancel</Button>
                </div>
            </Modal>
        </>
    );
};

export default AddOrder;
