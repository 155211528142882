import React from 'react';

import { AgGridReact } from "ag-grid-react";
import styled from "styled-components";
import { colors } from "../../constants";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // import Quartz theme

interface TableProps {
    rowData: any[];
    columns: any[];
    onRowClick?: (row: any) => void;
    onSelect?: (rows: any) => void;
    height?: number;
    checkboxes?: boolean;
    selectedIds?: string[];
    onCellChanged?: (params: any) => void;
    onCellClicked?: (params: any) => void;
}
const Table = ({ rowData, columns, onCellClicked, onRowClick, height, checkboxes, onSelect, selectedIds = [], onCellChanged }: TableProps) => {
    
  const onSelectionChanged = (params: { api: { getSelectedRows: () => any; }; }) => {
    const selectedRows = params.api.getSelectedRows();
    if (onSelect) {
      onSelect(selectedRows);
    }
  };

  const selectRowsByIds = (selectedIds: string[], api: any) => {
    api.forEachNode((node: any) => {
      console.log({ node })
      if (selectedIds.includes(node.data.id.toString())) {
        node.setSelected(true); 
      }
    });
  };

  const onCellValueChanged = (params: any) => {
    if (onCellChanged) {
      onCellChanged(params)
    }
  };

  const onGridReady = (params: any) => {
    if (selectedIds.length > 0) {
      selectRowsByIds(selectedIds, params.api); // Select rows based on selectedIds
    }
  };


  const columnsMod = checkboxes
    ? [...columns, {
      headerName: '', // Empty header for the checkbox column
      checkboxSelection: true, // Enable checkboxes for rows
      headerCheckboxSelection: true, // Enable a checkbox in the header to select/deselect all rows
      width: 50, 
      pinned: 'left',
    }]
    : columns 
    return (<TableContainer
                className="ag-theme-quartz"
                height={height}
              >
                <AgGridReact
                  columnDefs={columnsMod}
                  rowData={rowData}
                  defaultColDef={{
                    resizable: true,
                    flex: 1,
                  }}
                  onCellClicked={onCellClicked}
                  onRowClicked={onRowClick}
                  rowSelection="multiple"
                  suppressRowClickSelection={false}
                  onSelectionChanged={onSelectionChanged}
                  pagination={true}
                  paginationPageSize={10}
                  paginationPageSizeSelector={[10, 25, 50]}
                  onGridReady={onGridReady} // Hook into the grid's initialization
                  onCellValueChanged={onCellValueChanged}
                />
              </TableContainer>)
}

export default Table;

const TableContainer = styled.div<{ height?: number }>`
  height: ${({ height }) => height ? `${height}px` : undefined};
  .ag-root-wrapper {
    border-radius: 4px;
  }
  .ag-row-hover, .ag-row-selected {
    background-color: #e9ffdd;
    cursor: pointer;
  }
  
  .ag-header-row {
    color: ${colors.darkGreen};
    font-weight: bold;
  }

  .ag-paging-panel {
    font-size: 13px;
    border-top: 0;
  }

  .ag-picker-field-wrapper {
    border: none;
  }

  .ag-header-cell-resize:last-child {
    display: none;
  }

  .ag-paging-row-summary-panel,
  .ag-label,
  .ag-paging-description > span:first-child {
    @media screen and (max-width: 1000px) {
        display: none;
    }
  }

  .ag-paging-description > span:first-child {
    display: none;
  }

  .ag-paging-row-summary-panel {
    margin: 0;
  }
  
  .ag-paging-panel > * {
    @media screen and (max-width: 1000px) {
       margin: 0;
    }
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected) {
    border: none;
  }
`;