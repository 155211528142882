import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../constants';
import Input from '../../../components/Input';
import { User, useUser } from '../../../context/UserContext';
import FileUpload from '../../../components/FileUpload';
import MultiSelect from '../../../components/Multiselect';
import Select from '../../../components/Select';
import { industries, productsOfferedMock, certificationsMock, companySizes, countries_ } from '../../../mocks';
import Button from '../../../components/Button';
import { ButtonsContainer } from '..';
import { BottomButtonsWrapper, Content } from '../../../App';

const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const Column = styled.div`
    display: flex;   
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    flex-basis: 50%;
`;

const InputsContainer = styled.div`
    display: flex;
    gap: 40px;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 15px;
    }
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  position: relative;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${colors.darkGreen};
`;

const CompanyProfile: React.FC = () => {
  const { user, setUser } = useUser();
  
  const {
    companyName,
    overview,
    mission,
    industry,
    productsOffered,
    practiceDescription,
    certifications,
    supportingDocs,
    headquartersLocation,
    address,
    companyZip,
    companySize,
    website,
    socials
  } = user || {};

  const [formData, setFormData] = React.useState({
    companyName,
    overview,
    mission,
    industry,
    productsOffered,
    practiceDescription,
    certifications,
    supportingDocs,
    headquartersLocation,
    address,
    companyZip,
    companySize,
    website,
    socials
  });

  const saveAccount = async (): Promise<{ data: User }> => {
    if (!user) return { data: {} as User };

    const userData = {
        ...user,
        overview: formData.overview,
        mission: formData.mission,
        industry: formData.industry,
        productsOffered: formData.productsOffered,
        practiceDescription: formData.practiceDescription,
        certifications: formData.certifications,
        supportingDocs: formData.supportingDocs,
        headquartersLocation: formData.headquartersLocation,
        address: formData.address,
        companyZip: formData.companyZip,
        companySize: formData.companySize,
        website: formData.website,
        socials: formData.socials
    };

    setUser(userData)
    console.log({ userData })

    return { data: userData }
  };

  const submitAccount = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) return

    const { data } = await saveAccount();
    setUser(data)

    return { data }
  };

  return (
    <>      
      <Content>
        <Title>Company Profile</Title>
        <form onSubmit={submitAccount}>
            <InputsContainer>
            <Column>
                <Input
                    label="Company Name"
                    name="companyName"
                    value={formData.companyName || ''}
                />

                <Input
                    label="Company overview (Max 300 characters)"
                    name="overview"
                    textarea
                    maxLength={300}
                    value={formData.overview || ''}
                    onChange={(value) => setFormData({...formData, overview: value})}
                />

                <Input
                    label="Mission statement (Max 200 characters)"
                    name="mission"
                    textarea
                    maxLength={200}
                    value={formData.mission || ''}
                />

                <Select 
                    label='Company Industry'
                    name='industry'
                    value={formData.industry || ''}
                    options={industries}
                />

                <MultiSelect
                    label="Products/Services Offered"
                    name="productsOffered"
                    selectedValues={formData.productsOffered ? formData.productsOffered.split(', ') : []}
                    options={productsOfferedMock}
                />

                <Input
                    label="Describe your sustainability practice"
                    name="practiceDescription"
                    value={formData.practiceDescription || ''}
                />

                <MultiSelect
                    label="Certifications"
                    name="certifications"
                    selectedValues={formData.certifications ? formData.certifications.split(', ') : []}
                    options={certificationsMock}
                />

                <Input
                    label="Your supporting documents"
                    type="file"
                    multiple
                    name="documents"
                />  
                </ Column>
                
                <Column>

                <FormGroup>
                    <Label htmlFor="role">Company logo</Label>
                    <FileUpload type='picture' />
                </FormGroup>

                <Select 
                    label='Headquarters Location'
                    name='headquartersLocation'
                    value={formData.headquartersLocation || ''}
                    options={countries_}
                />

                <Input
                    label="Address"
                    name="address"
                    value={formData.address || ''}
                />  

                <Input
                    label="ZIP/ Postal code"
                    name="zip"
                    value={formData.companyZip || ''}
                />  

                <Select  
                    label="Company size"
                    options={companySizes}
                    name="companySize"
                    value={formData.companySize || ''}
                />  

                <Input  
                    label="Company Website"
                    name="website"
                    value={formData.website || ''}
                />  

                <Input  
                    label="Social Media Account(s)"
                    name="socials"
                    textarea
                    value={formData.socials || ''}
                />  
            </Column>
            </InputsContainer>
        </form>
        <BottomButtonsWrapper>
          <Button onClick={submitAccount}>Save</Button>
        </BottomButtonsWrapper>
      </Content>
    </>
  );
};



export default CompanyProfile;