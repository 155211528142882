import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { Container, Content, Title, Tabs, Tab, BottomButtonsWrapper } from '../../../App';
import { colors, TABS, URLS } from '../../../constants';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import { deliveriesColumns, contractItemsColumns } from '../config';
import RoundButton from '../../../components/RoundButton';
import { RoundButtonContainer } from '../../AdminPanel';
import { useSupplier } from '../../../context/SupplierContext';
import Input from '../../../components/Input';
import SendToSupplier from '../Components/SendToSupplierModal';
import Modal from '../../../components/Modal';
import Checkbox from '../../../components/Checkbox';

const TableBox = styled.div`
    border: 1px solid ${colors.tableBorder};
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const Subtitle = styled.h3`
    padding-top: 20px;
    padding-bottom: 10px;
    margin: 0;
    font-weight: 500;
`;

const Name = styled.span`
    color: grey;
`;

const Flex = styled.div`
    display: flex;
    gap: 20px;
    div {
        flex-basis: 100%;
    }
`

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    max-width: 300px;
    h3 {
        margin-bottom: 20px;
    }
    button {
        margin-bottom: 20px;
    }
`;

const OrderDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const navigate = useNavigate()

    const { orderId, contractId, deafaultTab } = state;

    const { contracts, setContracts } = useSupplier() || []
    const { orders = [], deliveries = []} = contracts.find(contract => contract.id === contractId ) || {}

    const orderIndex = orders?.findIndex((order) => order.poNumber === orderId) || 0
    const order = orders?.[orderIndex]

    const [activeTab, setActiveTab] = useState(deafaultTab || 'details');

    const handleDeliveryClick = (del: any) => {
        navigate(URLS.deliveryDetails, { state: { orderId: orderId, contractId, deliveryId: del.id } });
    }

    const [newOrderName, setNewOrderName] = useState(order?.poName)
    const [newDueDate, setNewDueDate] = useState(order?.dueDate)
    const [newDeliveryDate, setNewDeliveryDate] = useState(order?.deliveryDate)

    const [editMode, setEditMode] = useState(false);

    const save = () => {
        setEditMode(false)
        // setContracts([...contracts, ])

    }

    const [openModal, setOpenModal] = useState(false)

    const tabConfig = [
        {
          label: "PO details",
          key: "details",
          component:
            <>
            <Flex>
                <TableBox>
                    <p><Name>PO Number:</Name> {order?.poNumber}</p>
                    { !editMode
                        ? <p><Name>PO Name:</Name> {order?.poName}</p>
                    
                        : <p style={{ display: 'flex',  gap: 5, textWrap: 'nowrap', alignItems: 'center' }}>
                            <Name>PO Name:</Name>
                            <Input
                                type="text"
                                value={newOrderName}
                                style={{ marginBottom: 0 }}
                                onChange={(e) => setNewOrderName(e)} name='name'
                            />
                        </p>
                    }
                    <p><Name>Date Created:</Name> {order?.dateCreate}</p>
                    <p><Name>Date Issued:</Name> {order?.dateIssued}</p>
                    { !editMode
                        ? <p><Name>Due date:</Name> {order?.dueDate}</p>
                    
                        : <p style={{ display: 'flex',  gap: 5, textWrap: 'nowrap', alignItems: 'center' }}>
                            <Name>Due date:</Name>
                            <Input
                                type="date"
                                value={newDueDate}
                                style={{ marginBottom: 0 }}
                                onChange={(e) => setNewDueDate(e)} name='dueDate'
                            />
                        </p>
                    }

                    { !editMode
                        ? <p><Name>Delivery date:</Name> {order?.deliveryDate}</p>
                    
                        : <p style={{ display: 'flex',  gap: 5, textWrap: 'nowrap', alignItems: 'center' }}>
                            <Name>Delivery date:</Name>
                            <Input
                                type="date"
                                value={newDeliveryDate}
                                style={{ marginBottom: 0 }}
                                onChange={(e) => setNewDeliveryDate(e)} name='deliveryDate'
                            />
                        </p>
                    }

                    <p><Name>Status:</Name> {order?.status}</p>
                    <p><b><Name>Total:</Name> {order?.total.toFixed(2)}</b></p>
                </TableBox>

                <ButtonsWrapper>
                    <Subtitle>Actions</Subtitle>
                    <Button>Edit due date</Button>
                    <Button onClick={() => setEditMode(true)}>Edit PO</Button>
                    <Button>Add new delivery</Button>
                    <Button>Mark as fully received</Button>
                    <Button>Close PO</Button>
                    <Button>Clone PO</Button>
                    <Button>Cancel PO</Button>
                    <Button onClick={() => setOpenModal(true)}>Send to supplier</Button>
                </ButtonsWrapper>
            </Flex>
            <Subtitle>Order items</Subtitle>
            <div style={{ paddingBottom: '20px'}}>
                <Table rowData={order?.items || []} columns={contractItemsColumns(false)} height={300} />
            </div>

           <BottomButtonsWrapper>
                { editMode ?
                    <>
                        <Button onClick={save}>Save</Button>
                        <Button onClick={() => setEditMode(false)}>Cancel</Button>
                    </>
                    : <Button onClick={() => navigate(URLS.contractDetails, { state: { contractId, defaultTab: 'purchaseOrders' } })} width="150px">
                        Back to PO list
                    </Button>
                }
            </BottomButtonsWrapper>

            </>
        },
        {
            label: "Deliveries",
            key: "deliveries",
            component:
              <>
                  <div style={{ paddingBottom: '20px'}}>
                      <Table rowData={deliveries} columns={deliveriesColumns} height={300} onRowClick={(row) => handleDeliveryClick(row.data)}/>
                  </div>

                  <RoundButtonContainer>
                    <RoundButton onClick={() => navigate(URLS.addDelivery, { state: { orderId, contractId }})} text='Add new delivery' />
                </RoundButtonContainer>
              </>
          },
          { 
            label: 'History',
            key: 'history',
            component: <></>
          },
          { 
            label: 'PO documents',
            key: 'poDocuments',
            component: <></>
          }
      ];

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Order { orderId }</Title>

                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setActiveTab(tabItem.key)}
                                active={activeTab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>
                    
                    { tabConfig.find(({ key }) => key === activeTab)?.component }

                </Content>
            </Container>
            <Modal isOpen={openModal}>
                <Title>Send PO to supplier</Title>
                <Checkbox label='Require supplier confirmation' checked={false} name='supplier'></Checkbox>
                <SendToSupplier send={() => console.log('sent')} cancel={() => setOpenModal(false)} />
            </Modal>
        </>
    );
};

export default OrderDetails;