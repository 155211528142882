import React, { useState } from 'react';
import Input from '../../../components/Input';
import { User, useUser } from '../../../context/UserContext';
import Select from '../../../components/Select';
import { countries_ } from '../../../mocks';
import MultiSelect from '../../../components/Multiselect';
import Button from '../../../components/Button';
import { Content, StyledForm } from '..';
import { BottomButtonsWrapper } from '../../../App';

const PersonalProfile: React.FC = () => {
  const { user, setUser } = useUser();
  
  const {
    firstname,
    lastname,
    email,
    phone,
    userCity,
    userCountry,
    userRegion,
    userZip,
    preferredCommunicationMethod
  } = user || {};

  const [formData, setFormData] = useState({
    firstname,
    lastname,
    email,
    phone,
    userCity,
    userCountry,
    userRegion,
    userZip,
    preferredCommunicationMethod
  });

  const saveAccount = async (): Promise<{ data: User }> => {
    if (!user) return { data: {} as User };
    if (!formData.firstname || !formData.lastname || !formData.email) return { data: {} as User };

    const userData = {
        ...user,
        firstname: formData.firstname,
        lastname: formData.lastname,
        email: formData.email,
        phone: formData.phone,
        userCity: formData.userCity,
        userCountry: formData.userCountry,
        userRegion: formData.userRegion,
        userZip: formData.userZip,
        preferredCommunicationMethod: formData.preferredCommunicationMethod
    };

    setUser(userData)

    return { data: userData }
  };

  const submitAccount = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!user) return
    if (!formData.firstname || !formData.lastname || !formData.email) return

    const { data } = await saveAccount();

    console.log({ data })

    setUser(data)
  };

  return (
    <>      
      <Content>
        <StyledForm onSubmit={submitAccount}>          
              <Input
                label="First Name"
                name="firstname"
                value={formData.firstname}
                onChange={(value) => setFormData({...formData, firstname: value})}
                required
            />

            <Input
                label="Last Name"
                name="lastname"
                value={formData.lastname}
                onChange={(value) => setFormData({...formData, lastname: value})}
                required
            />      

            <Input
                label="Business email"
                name="email"
                value={formData.email}
                onChange={(value) => setFormData({...formData, email: value})}
                required
            />  

            <Input
                label="Phone number"
                name="phone"
                value={formData.phone}
                onChange={(value) => setFormData({...formData, phone: value})}
            />  

            <Input
                label="City"
                name="userCity"
                value={formData.userCity}
                onChange={(value) => setFormData({...formData, userCity: value})}
            />

            <Select
                options={countries_}
                label="Country"
                name="userCountry"
                value={formData.userCountry || ''}
                onChange={(e) => setFormData({...formData, userCountry: e.target.value})}
            />            

            <Input
                label="Region"
                name="userRegion"
                value={formData.userRegion}
                onChange={(value) => setFormData({...formData, userRegion: value})}
            />

            <Input
                label="Zip"
                name="userZip"
                value={formData.userZip}
                onChange={(value) => setFormData({...formData, userZip: value})}
            />

            <MultiSelect
              options={[ { value: 'email', label: 'Email' }, { value: 'phone', label: 'Phone' } ]}
              label="Preferred communication method"
              name="preferredCommunicationMethod"
              selectedValues={formData.preferredCommunicationMethod?.length ? formData.preferredCommunicationMethod?.split(', ') : []}
              onChange={(values) => setFormData({...formData, preferredCommunicationMethod: values.join(', ')})}
            />

         
        </StyledForm>
        <BottomButtonsWrapper>
          <Button onClick={submitAccount}>Save</Button>
        </BottomButtonsWrapper>

      </Content>
    </>
  );
};



export default PersonalProfile;