import React, { useState } from 'react';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import { BottomButtonsWrapper, Container, Content, Tab, Tabs, Title, TopButtonsWrapper } from '../../../App';
import { TABS, URLS } from '../../../constants';
import Button from '../../../components/Button';
import { useSupplier } from '../../../context/SupplierContext';
import { Contract } from '../../../interfaces';
import { randomId } from '../../../utils';
import Details from '../Components/Details';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../components/Modal';

interface Props {
    contract: Contract;
    requestId: number;
}

const CreateFromOffer: React.FC<Props> = ({ contract, requestId }) => {
    const [newContract, setNewContract] = useState<Contract>(contract);

    const { contracts, setContracts } = useSupplier();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const navigate = useNavigate()
    const submitContract = () => {
        const newId = randomId()
        setContracts([...contracts, { ...newContract, id: newId }]);
        setShowConfirmationModal(true)

    };

    const [editMode, setEditMode] = useState(false)

    const save = () => {
        setEditMode(false)
    }

    const tabConfig = [
        {
          label: "Details",
          key: "details",
          component: 
          <>
            <TopButtonsWrapper>
                <Button>Export in PDF</Button>
            </TopButtonsWrapper>

            <Details
                contract={newContract}
                setContract={setNewContract}
                isEditModeOn={editMode}
                hideAttachDocuments
                requestId={requestId}
            />
            <BottomButtonsWrapper>
                { !editMode
                    ? <Button onClick={() => setEditMode(true)} width='200px'>Edit Contract</Button>
                    : <Button onClick={save} width='200px'>Save Contract</Button>
                }
            
                <Button onClick={submitContract} width='200px'>Send to supplier</Button>
            </BottomButtonsWrapper>
            <Modal isOpen={showConfirmationModal}>
                <div style={{ paddingBottom: 20}}>
                    Contract sent to supplier
                </div>
                <Button onClick={() => {
                    setShowConfirmationModal(false)
                    navigate(URLS.contracts)
                }}>Ok</Button>
            </Modal>
        </>
        },
        {
            label: "Documents",
            key: "documents",
            component: <div></div>
          }
    ]

    const [activeTab, setActiveTab] = useState('details')

    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.contracts} />
                
                <Content>
                    <Title>Create Contract</Title>
                    
                    <Tabs>
                        {tabConfig.map((tabItem, index) => (
                            <Tab
                                key={tabItem.key}
                                onClick={() => setActiveTab(tabItem.key)}
                                active={activeTab === tabItem.key}
                                tabIndex={index}
                                role="button"
                            >
                                {tabItem.label}
                            </Tab>
                        ))}
                    </Tabs>
                    
                    { tabConfig.find(({ key }) => key === activeTab)?.component }


                </Content>
            </Container>
        </>
    );
};

export default CreateFromOffer;
