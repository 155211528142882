import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';

interface ReminderButtonProps {
    data: any;
}
const ReminderButton: React.FC<ReminderButtonProps> = ({ data }) => {
    const onButtonClick = (e: any) => {
        e.stopPropagation(); 
    }

    const { status } = data;

    if (status !== "waiting_for_offer") {
        return null
    }
  return (
    <div onClick={(e) => onButtonClick(e)}>
      <Button width="120px" style={{ fontSize: 14, padding: 8 }}>Send reminder</Button>
    </div>
  );
};

export default ReminderButton;