import React, { useState } from 'react';
import styled from 'styled-components';
import Sidebar from '../../../components/Sidebar';
import { colors, inputBorderRadius, TABS, URLS } from '../../../constants';
import Header from '../../../components/Header';
import Table from '../../../components/Table';
import RoundButton from '../../../components/RoundButton';
import { useNavigate } from 'react-router-dom';
import { useSupplier } from '../../../context/SupplierContext';
import { Contract } from '../../../interfaces';

const Contracts: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const { contracts } = useSupplier();

  const columns = [
    { field: 'id', filter: true, maxWidth: 80 },
    { field: 'supplierId', filter: true },
    { field: 'productCategory', filter: true },
    { field: 'certifications', filter: true },
    { field: 'status', filter: true },
    { field: 'endDate', filter: true },
    { field: 'description', filter: true },
  ]
  
  // Filter contracts based on the search term
  const filteredContracts = contracts
    .map((contract) => {
      return {
        ...contract,
        productCategory: Array.from(
          new Set(
            contract.items
              .map(item => item.productCategory)          // Extract the 'tags' field
              .flatMap(productCategory => productCategory.split(',')) // Split by ',' and flatten
          )
        ),
        certifications: Array.from(
          new Set(
            contract.items
              .map(item => item.certifications)          // Extract the 'tags' field
              .flatMap(cert => cert.split(',')) // Split by ',' and flatten
          )
        ),
      }
    })
    .filter((contract) =>
      columns.some((column) =>
        contract[column.field as keyof typeof contract]
          ?.toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );

  const handleClickOnRow = (contract: Contract) => {
    navigate(URLS.contractDetails, { state: { contractId: contract.id } });
  };

  return (
    <>
      <Header />
      <Container>
        <Sidebar tab={TABS.contracts} />
        
        <Content>
          <Title>Contracts</Title>
          <SearchBarWrapper>
            <SearchInput
              type="text"
              placeholder="Search contracts..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SearchBarWrapper>
          <Table height={500} columns={columns} rowData={filteredContracts} onRowClick={(row) => handleClickOnRow(row.data)} />
          <RoundButtonWrapper>
            <RoundButton onClick={() => navigate(URLS.addContract)} text='Add new contract' size={60}/>
          </RoundButtonWrapper>
        </Content>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - 60px);
`;

const Content = styled.div`
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
`;

const Title = styled.h2`
  text-align: center;
  color: ${colors.darkGreen};
  margin-bottom: 20px;
  margin-top: 0;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
`;

const SearchInput = styled.input`
  padding: 10px;
  font-size: 14px;
  width: 100%;
  border: 1px solid ${colors.lightGreen};
  border-radius: ${inputBorderRadius};
`;

const RoundButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export default Contracts;
