import React, { createContext, useContext, ReactNode, useState } from 'react';
import { Contract, Request, Supplier } from '../interfaces';
import { departmentsMock, requestsMock, suppliers as suppliersMock } from '../mocks';
import { contracts as contractsMock } from '../mocks';
import { Department } from '../pages/AdminPanel/interfaces';

interface SupplierContextType {
  suppliers: Supplier[];
  setSuppliers: (suppliers: Supplier[]) => void;
  contracts: Contract[];
  setContracts: (contracts: Contract[]) => void;
  departments: Department[];
  setDepartments: (departments: Department[]) => void;
  requests: Request[];
  setRequests: (requests: Request[]) => void;
}

const SupplierContext = createContext<SupplierContextType>({} as SupplierContextType);

export const SupplierProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [suppliers, setSuppliers] = useState<Supplier[]>(suppliersMock);
  const [contracts, setContracts] = useState<Contract[]>(contractsMock as Contract[])
  const [departments, setDepartments] = useState<Department[]>(departmentsMock)
  const [requests, setRequests] = useState<Request[]>(requestsMock)

  return (
    <SupplierContext.Provider
      value={{ suppliers, setSuppliers, contracts, setContracts, departments, setDepartments, requests, setRequests }}
    >
      {children}
    </SupplierContext.Provider>
  );
};

export const useSupplier = (): SupplierContextType => {
  const context = useContext(SupplierContext);
  if (!context) {
    throw new Error('useSupplier must be used within a AppProvider');
  }
  return context;
};
