export const contractItemsColumns = (isEditable: boolean) => [
    {
        field: 'id',
        filter: true,
        maxWidth: 80
    },
    {
        field: 'productCategory',
        filter: true,
    },
    {
        field: 'material',
        filter: true,
    },
    {
        field: 'capacityRange',
        filter: true,
    },
    {
        field: 'quantity',
        filter: true,
        editable: isEditable
    },
    {
        field: 'uom',
        filter: true,
    },
    {
        field: 'unitPrice',
        filter: true,
    },
    {
        field: 'subtotal',
        filter: true,
    },
]

export const ordersColumn = [
    {
        field: 'poNumber',
        filter: true,
    },
    {
        field: 'poName',
        filter: true,
    },
    {
        field: 'dateCreate',
        filter: true,
    },
    {
        field: 'dateIssued',
        filter: true,
    },
    {
        field: 'dueDate',
        filter: true,
    },
    {
        field: 'status',
        filter: true,
    },
    {
        field: 'total',
        filter: true,
    }
]

export const deliveriesColumns = [
    {
        field: 'id',
        filter: true,
        maxWidth: 80
    },
    {
        field: 'date',
        filter: true,
    },
    {
        field: 'method',
        filter: true,
    },
    {
        field: 'itemsForDelivery',
        filter: true,
    },
    {
        field: 'status',
        filter: true,
    },
]

export const orderDeliveriesColumns = [
    {
        field: 'id',
        filter: true,
        maxWidth: 80
    },
    {
        field: 'productCategory',
        filter: true,
    },
    {
        field: 'details',
        filter: true,
    },
    {
        field: 'uom',
        filter: true,
    },
    {
        field: 'quantityOrdered',
        filter: true,
    },
    {
        field: 'totalRecievedDeliveried',
        filter: true,
    },
    {
        field: 'qtyDelivered',
        filter: true,
        editable: true,
    },
    {
        field: 'qtyReceived',
        filter: true,
        editable: true,

    },
    {
        field: 'notes',
        filter: true,
        editable: true,

    },

]