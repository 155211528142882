import React, { useState } from 'react';
import { TableBox } from '../styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomButtonsWrapper, Container, Title } from '../../../App';
import Header from '../../../components/Header';
import Sidebar from '../../../components/Sidebar';
import { colors, TABS, URLS } from '../../../constants';
import { Content } from '../../Profile';
import styled from 'styled-components';
import Button from '../../../components/Button';
import { Application } from '../../../interfaces';
import { useSupplier } from '../../../context/SupplierContext';
import { suppliers } from '../../../mocks';
import Input from '../../../components/Input';
import Table from '../../../components/Table';
import { requestItemsColumns } from '../config';
import { FaDownload } from "react-icons/fa";
import { randomId } from '../../../utils';

const Name = styled.span`
    color: grey;
`;

const ApplicationDetails: React.FC = () => {
    const location = useLocation();
    const state = location.state;

    const { appId, requestId } = state;

    const { requests, setRequests } = useSupplier()
    const request = requests.find(req => req.id === requestId)

    const applications = request?.applications || []

    const appIndex = applications.findIndex((app: Application) => app.id === appId) || 0
    const application = applications[appIndex]
    
    const [currency, setCurrency] = useState('');

    const navigate = useNavigate()

    const estimatedValue = 100000
    const acceptOffer = () => {
        const newId = randomId()
        const newContract = {
            id: newId,
            title: request?.name,
            type: '',
            supplierId: application.supplierId,
            country: '',
            department: '',
            startDate: '',
            endDate: '',
            noticePeriodDate: '',
            currency: currency,
            estimatedValue: estimatedValue,
            description: 'test request',
            items: request?.items,
            orders: [],
            deliveries: [],
            status: 'closed'
        }

        navigate(URLS.addContract, { state: { contract: newContract, requestId }}) 
    }

    const rejectOffer = () => {
        application.status = 'rejected'
        setRequests([...requests])
        navigate(URLS.requests) 

    }

    const supplierName = suppliers.find(sup => sup.id === application.supplierId)?.company
    return (
        <>
            <Header />
            <Container>
                <Sidebar tab={TABS.requests} />
                
                <Content>
                    <Title>Application {appId}</Title>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 15 }}>
                        <Button width='200px' onClick={() => navigate(URLS.requestDetails, { state: { requestId }})}>Back to opportunity details</Button>
                    </div>

                    <div style={{ display: 'flex', gap: '20px' }}>
                        <TableBox>
                            <div><Name>Opportunity title:</Name> {request?.name}</div>

                            <div><Name>Supplier name:</Name> {supplierName}</div>
                            <div><Name>Address:</Name> -</div>
                        </TableBox>

                        <TableBox>
                            <div><Name>Start date:</Name> {request?.startDate}</div>

                            <div><Name>Due date:</Name> {request?.dueDate}</div>
                            <div><Name>Notice period date:</Name> - </div>
                            <div><Name>Currency:</Name> {request?.currency}</div>
                            <div><Name>Estimated contract value:</Name> {estimatedValue}</div>
                        </TableBox>
               
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <Name>Opportunity description:</Name>
                        <Input textarea name="description" disabled></Input>
                    </div>
                    <Name>Opportunity items</Name>
                    <div style={{ paddingBottom: '20px'}}>
                        <Table rowData={request?.items || []} columns={requestItemsColumns} height={300} />
                    </div>
                    <Name>Documents:</Name>
                    <div style={{ display: 'flex', gap: 10, padding: 10, alignItems: 'center' }}>
                        <div>Document1</div><FaDownload />
                    </div>
                    <div style={{ display: 'flex', gap: 10, padding: 10, alignItems: 'center' }}>
                        <div>Document2</div><FaDownload />
                    </div>

                    <BottomButtonsWrapper>
                        <Button style={{ minWidth: 240 }} color={colors.green} onClick={acceptOffer}>Accept offer & create contract</Button>
                        <Button color={colors.red} onClick={rejectOffer}>Reject offer</Button>
                        <Button>Compare offers</Button>
                    </BottomButtonsWrapper>
                </Content>
            </Container>
                
        </>
    )
}

export default ApplicationDetails;